/* fade right */

.fade_right-enter > div {
  opacity: 0;
  transform: translatex(100%);
}

.fade_right-enter-active > div {
  opacity: 1;
  transform: translatex(0%);
}

.fade_right-exit > div {
  opacity: 1;
  transform: translatex(0%);
}

.fade_right-exit-active > div {
  opacity: 0;
  transform: translatex(100%);
}

.fade_right-exit-active > div,
.fade_right-enter-active > div {
  transition: opacity 500ms, transform 500ms;
}

/* fade left */

.fade_left-enter > div {
  opacity: 0;
  transform: translatex(-100%);
}

.fade_left-enter-active > div {
  opacity: 1;
  transform: translatex(0%);
}

.fade_left-exit > div {
  opacity: 1;
  transform: translatex(0%);
}

.fade_left-exit-active > div {
  opacity: 0;
  transform: translatex(-100%);
}

.fade_left-exit-active > div,
.fade_left-enter-active > div {
  transition: opacity 500ms, transform 500ms;
}

div.transition-group {
  position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
