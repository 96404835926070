.sidebar .ant-card-body {
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 1rem;
  cursor: pointer;
  transition: background 140ms ease-in;
}

.sidebar__highlight:hover {
  background: rgba(58, 56, 52, 0.08);
}


.table-center {
  text-align: center;
}
